import request from '@/utils/request'

const api = {
  user: '/admincp/role/index',
  usercreate: '/admincp/role/create',
  userupdate: '/admincp/role/update',
  userinfo: '/admincp/role/info',
  userDel: '/admincp/role/delete',
  userDelete: '/admincp/role/moredel',
  allList: '/admincp/role/allList',
  permissionSave: '/admincp/role/permissionsave',
  GetPermission: '/admincp/role/permissionlist'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.usercreate,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userupdate,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDel,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDelete,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function allList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.allList,
    method: 'post',
    data: parameter
  })
}

export function permissionSave (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.permissionSave,
    method: 'post',
    data: parameter
  })
}

export function GetPermission (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.GetPermission,
    method: 'post',
    data: parameter
  })
}
