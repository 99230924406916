<template>
  <a-tree
    v-model="selected"
    :replaceFields="replaceFields"
    :tree-data="treeData"
    allow-clear
    checkable
    class="treeQmu"
    tree-default-expand-all
    @check="onCheck"
  />
</template>

<script>
import { allListTree } from '@/api/menu'
import { GetPermission } from '@/api/role'

export default {
  data () {
    return {
      treeData: [],
      selected: [],
      roleId: 0,
      replaceFields: { children: 'children', title: 'title', key: 'id', value: 'id' }
    }
  },

  methods: {
    getAllList (roleId) {
      return allListTree({ roleId: roleId })
        .then(res => {
          this.treeData = res.result
        })
    },
    onCheck (checkedKeys, info) {
      this.selected = checkedKeys
    },
    selectedValue (roleId) {
      this.getAllList(roleId)
      this.roleId = roleId
      this.selected = []
      GetPermission({ 'roleId': roleId })
        .then(res => {
          if (res.code === 1) {
            const data = res.result
            if (data != null) {
              const temp = []
              for (let i = 0; i < data.length; i++) {
                temp.push(data[i].menuId)
              }
              this.selected = temp
            }
          }
        })
    },
    GetselectedValue () {
      if (this.selected.length > 0) {
        return { 'roleId': this.roleId, 'data': this.selected.join(',') }
      } else {
        return null
      }
    }
  }
}
</script>
